<template>
  <v-app>
    <transition>
      <router-view></router-view>
    </transition>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      countries: [],
    };
  },

  methods: {
    getCountries() {
      this.axios({
        method: "GET",
        url: `country_not_paginated`,
      }).then((res) => {
        this.countries =
          res.data.data &&
          res.data.data.map((item) => {
            return {
              id: item.id,
              name: item[this.$i18n.locale]?.name,
            };
          });

        this.$store.commit("setCountries", this.countries);
      });
    },
  },

  computed: {},

  mounted() {
    this.getCountries();

    // ===> Vuetify Direction
    if (this.$store.getters["lang_module/lang"] == "ar") {
      document.body.dir = "rtl";
      this.$vuetify.rtl = true;
    } else {
      document.body.dir = "ltr";
      this.$vuetify.rtl = false;
    }

    // ===> Vuetify Theme
    if (this.$store.getters["theme_module/current_theme"] == "light") {
      this.$vuetify.theme.dark = false;
    } else {
      this.$vuetify.theme.dark = true;
    }

    // ===> Scroll To Top Alwayse
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },
};
</script>

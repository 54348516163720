<template>
  <div class="h-100 d-flex justify-content-center no-data">
    <img class="img-fluid" src="@/assets/media/images/logo/logo.png" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.no-data {
  img {
    max-width: 800px;
    max-height: 600px;
  }
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";

// ============================== Start Views ==============================
// Home
import Home from "../views/Home.vue";

// ********** Children
import HomePage from "../views/children/HomePage.vue";

// ========== Start Profiles ==========

// ========== End Profiles ==========

// ============================== START:: CRUDS ==============================

// ********** Auth
import Login from "../views/Auth/Login.vue";

// ********** NotFound
import NotFound from "../views/NotFound.vue";

// ******* End Contact Messages *******
// ============================== End Views ==============================

// Vuex store
import store from "../store/index";
let router = null;
window.rootRouter = router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    // Main
    {
      path: "/",
      component: Home,
      children: [
        {
          path: "/",
          name: "HomePage",
          component: HomePage,
        },
        // ============================== Start CRUDS ==============================
        // ******* Start Countries *******
        {
          path: "/countries",
          component: () => import("../views/children/CRUDS/Countries/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllCountries",
              component: () =>
                import("../views/children/CRUDS/Countries/ShowAll.vue"),
            },
            // Show Single
            {
              path: "show/:id",
              name: "CountryShow",
              component: () =>
                import("../views/children/CRUDS/Countries/Show.vue"),
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "CountryEdit",
              component: () =>
                import("../views/children/CRUDS/Countries/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "CountryAdd",
              component: () =>
                import("../views/children/CRUDS/Countries/Form.vue"),
            },
          ],
        },
        // ******* End Countries *******
        // ******* Start Cities *******
        {
          path: "/cities",
          component: () => import("../views/children/CRUDS/Cities/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllCities",
              component: () =>
                import("../views/children/CRUDS/Cities/ShowAll.vue"),
            },
            // Show Single
            {
              path: "show/:id",
              name: "CityShow",
              component: () =>
                import("../views/children/CRUDS/Cities/Show.vue"),
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "CityEdit",
              component: () =>
                import("../views/children/CRUDS/Cities/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "CityAdd",
              component: () =>
                import("../views/children/CRUDS/Cities/Form.vue"),
            },
          ],
        },
        // ******* End Cities *******

        // ******* Start Wallet *******
        {
          path: "/wallet",
          component: () => import("../views/children/CRUDS/Wallet/Home.vue"),
          children: [
            // Charge
            {
              path: "charge",
              name: "chargeWallet",
              component: () =>
                import("../views/children/CRUDS/Wallet/ChargeWallet.vue"),
            },

            // withdraw
            {
              path: "withdraw",
              name: "WithdrawWallet",
              component: () =>
                import("../views/children/CRUDS/Wallet/WithdrawWallet.vue"),
            },
          ],
        },
        // ******* End Wallet *******

        // ******* Start PaymentWays *******
        {
          path: "/payment_ways",
          component: () =>
            import("../views/children/CRUDS/PaymentWays/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllPaymentWays",
              component: () =>
                import("../views/children/CRUDS/PaymentWays/ShowAll.vue"),
            },

            // Edit
            {
              path: "edit/:id",
              name: "PaymentWayEdit",
              component: () =>
                import("../views/children/CRUDS/PaymentWays/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "PaymentWayAdd",
              component: () =>
                import("../views/children/CRUDS/PaymentWays/Form.vue"),
            },
          ],
        },
        // ******* End PaymentWays *******

        // ******* Start Sliders *******
        {
          path: "/sliders",
          component: () => import("../views/children/CRUDS/Sliders/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllSliders",
              component: () =>
                import("../views/children/CRUDS/Sliders/ShowAll.vue"),
            },

            // Edit
            {
              path: "edit/:id",
              name: "SliderEdit",
              component: () =>
                import("../views/children/CRUDS/Sliders/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "SliderAdd",
              component: () =>
                import("../views/children/CRUDS/Sliders/Form.vue"),
            },
          ],
        },
        // ******* End Sliders *******

        // ******* Start Coupons *******
        {
          path: "/coupons",
          component: () => import("../views/children/CRUDS/Coupons/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllCoupons",
              component: () =>
                import("../views/children/CRUDS/Coupons/ShowAll.vue"),
            },
            // Show Single
            {
              path: "show/:id",
              name: "CouponShow",
              component: () =>
                import("../views/children/CRUDS/Coupons/Show.vue"),
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "CouponEdit",
              component: () =>
                import("../views/children/CRUDS/Coupons/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "CouponAdd",
              component: () =>
                import("../views/children/CRUDS/Coupons/Form.vue"),
            },
          ],
        },
        // ******* End Coupons *******

        // ******* Start Cancel Reasons *******
        {
          path: "/cancelReasons",
          component: () =>
            import("../views/children/CRUDS/CancelReasons/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllReports",
              component: () =>
                import("../views/children/CRUDS/CancelReasons/ShowAll.vue"),
            },

            // Edit
            {
              path: "edit/:id",
              name: "ReportEdit",
              component: () =>
                import("../views/children/CRUDS/CancelReasons/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "ReportAdd",
              component: () =>
                import("../views/children/CRUDS/CancelReasons/Form.vue"),
            },
          ],
        },
        // ******* End Cancel Reasons *******

        // ******* Start Group Services *******
        {
          path: "/groupServices",
          component: () =>
            import("../views/children/CRUDS/GroupServices/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllGroupServices",
              component: () =>
                import("../views/children/CRUDS/GroupServices/ShowAll.vue"),
            },

            // Edit
            {
              path: "edit/:id",
              name: "GroupServicesEdit",
              component: () =>
                import("../views/children/CRUDS/GroupServices/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "GroupServicesAdd",
              component: () =>
                import("../views/children/CRUDS/GroupServices/Form.vue"),
            },
          ],
        },
        // ******* End Group Services *******

        // ******* Start Sub Services *******
        {
          path: "/subServices",
          component: () =>
            import("../views/children/CRUDS/SubServices/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllSubServices",
              component: () =>
                import("../views/children/CRUDS/SubServices/ShowAll.vue"),
            },

            // Edit
            {
              path: "edit/:id",
              name: "SubServicesEdit",
              component: () =>
                import("../views/children/CRUDS/SubServices/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "SubServicesAdd",
              component: () =>
                import("../views/children/CRUDS/SubServices/Form.vue"),
            },
          ],
        },
        // ******* End Sub Services *******

        // ******* Start Clients *******
        {
          path: "/clients",
          component: () => import("@/views/children/CRUDS/Clients/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllClients",
              component: () =>
                import("@/views/children/CRUDS/Clients/ShowAll.vue"),
            },

            // Show
            {
              path: "show/:id",
              name: "ClientsShow",
              component: () =>
                import("../views/children/CRUDS/Clients/Show.vue"),
              props: true,
            },

            // Edit
            {
              path: "edit/:id",
              name: "editClient",
              component: () =>
                import("../views/children/CRUDS/Clients/Form.vue"),
              props: true,
            },
          ],
        },
        // ******* End Clients *******

        // ******* Start cashOutRequests *******
        {
          path: "/cashout-requests",
          component: () =>
            import("@/views/children/CRUDS/CashOutRequests/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllcashOutRequests",
              component: () =>
                import("@/views/children/CRUDS/CashOutRequests/ShowAll.vue"),
            },
          ],
        },
        // ******* End cashOutRequests *******

        // ******* Start Providers *******
        {
          path: "/providers",
          component: () => import("@/views/children/CRUDS/Providers/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllProviders",
              component: () =>
                import("@/views/children/CRUDS/Providers/ShowAll.vue"),
            },

            // Show
            {
              path: "show/:id",
              name: "ProviderShow",
              component: () =>
                import("../views/children/CRUDS/Providers/Show.vue"),
              props: true,
            },

            // Add
            {
              path: "add",
              name: "addProvider",
              component: () =>
                import("../views/children/CRUDS/Providers/Form.vue"),
            },

            // Edit
            {
              path: "edit/:id",
              name: "editProvider",
              component: () =>
                import("../views/children/CRUDS/Providers/Form.vue"),
              props: true,
            },
          ],
        },
        // ******* End Providers *******

        // ******* Start WorkingHours *******
        {
          path: "/working_hours",
          component: () =>
            import("@/views/children/CRUDS/WorkingHours/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllWorkingHours",
              component: () =>
                import("@/views/children/CRUDS/WorkingHours/ShowAll.vue"),
            },

            // Add
            {
              path: "add",
              name: "addWorkingHour",
              component: () =>
                import("../views/children/CRUDS/WorkingHours/Form.vue"),
            },

            // Edit
            {
              path: "edit/:id",
              name: "editWorkingHour",
              component: () =>
                import("../views/children/CRUDS/WorkingHours/Form.vue"),
              props: true,
            },
          ],
        },
        // ******* End WorkingHours *******

        // ******* Start Orders *******
        {
          path: "/orders",
          component: () => import("@/views/children/CRUDS/Orders/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllOrders",
              component: () =>
                import("@/views/children/CRUDS/Orders/ShowAll.vue"),
            },

            // show single
            {
              path: "show/:id",
              name: "OrderShow",
              component: () => import("@/views/children/CRUDS/Orders/Show.vue"),
              props: true,
            },

            // Add
            {
              path: "add",
              name: "addOrder",
              component: () => import("@/views/children/CRUDS/Orders/Form.vue"),
            },
          ],
        },
        // ******* End Orders *******

        // ******* Start Provider Services *******
        {
          path: "/providerServices",
          component: () =>
            import("../views/children/CRUDS/ProviderServices/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllProviderServices",
              component: () =>
                import("../views/children/CRUDS/ProviderServices/ShowAll.vue"),
            },

            // Show
            {
              path: "show/:id",
              name: "ProviderServiceShow",
              component: () =>
                import("../views/children/CRUDS/ProviderServices/Show.vue"),
              props: true,
            },

            // Edit
            {
              path: "edit/:id",
              name: "ProviderServiceEdit",
              component: () =>
                import("../views/children/CRUDS/ProviderServices/Form.vue"),
              props: true,
            },

            // Add
            {
              path: "add",
              name: "ProviderServiceAdd",
              component: () =>
                import("../views/children/CRUDS/ProviderServices/Form.vue"),
            },
          ],
        },
        // ******* End Provider Services *******

        // ******* Start ContactMessages *******
        {
          path: "/contact_messages",
          component: () =>
            import("@/views/children/CRUDS/ContactMessages/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllContactMessages",
              component: () =>
                import("@/views/children/CRUDS/ContactMessages/ShowAll.vue"),
            },

            // Show
            {
              path: "show/:id",
              name: "ContactMessageShow",
              component: () =>
                import("@/views/children/CRUDS/ContactMessages/Show.vue"),
              props: true,
            },
          ],
        },
        // ******* End ContactMessages *******

        // ******* Start notifications *******
        {
          path: "/notifications",
          component: () =>
            import("@/views/children/CRUDS/notifications/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "all-notifications",
              component: () =>
                import("@/views/children/CRUDS/notifications/ShowAll.vue"),
            },
            // Show Single

            {
              path: "add",
              name: "add-notification",
              component: () =>
                import("@/views/children/CRUDS/notifications/Form.vue"),
            },
          ],
        },
        // ******* End notifications *******

        // ******* Start  Permission *******
        // {
        //   path: "/permissions",
        //   component: () =>
        //     import("@/views/children/CRUDS/Roles/Permissions/Home.vue"),
        //   children: [
        //     // Show All
        //     {
        //       path: "show-all",
        //       name: "AllPermissions",
        //       component: () =>
        //         import("@/views/children/CRUDS/Roles/Permissions/ShowAll.vue"),
        //     },

        //     // Edit
        //     {
        //       path: "edit/:id",
        //       name: "EditPermission",
        //       component: () =>
        //         import("@/views/children/CRUDS/Roles/Permissions/Form.vue"),
        //       props: true,
        //     },
        //     // Edit
        //     {
        //       path: "add",
        //       name: "AddPermission",
        //       component: () =>
        //         import("@/views/children/CRUDS/Roles/Permissions/Form.vue"),
        //     },
        //   ],
        // },
        // ******* End  Permission *******

        // ******* Start  roles *******
        {
          path: "/roles",
          component: () => import("@/views/children/CRUDS/Roles/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "all-roles",
              component: () =>
                import("@/views/children/CRUDS/Roles/ShowAll.vue"),
            },
            // Show Single

            // Edit
            {
              path: "edit/:id",
              name: "edit-role",
              component: () => import("@/views/children/CRUDS/Roles/Form.vue"),
              props: true,
            },
            // Edit
            {
              path: "add",
              name: "add-role",
              component: () => import("@/views/children/CRUDS/Roles/Form.vue"),
            },
          ],
        },
        // ******* End  roles *******

        // ******* Start  Admins *******
        {
          path: "/admins",
          component: () => import("@/views/children/CRUDS/Admins/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllAdmins",
              component: () =>
                import("@/views/children/CRUDS/Admins/ShowAll.vue"),
            },
            // Show Single
            {
              path: "show/:id",
              name: "adminShow",
              component: () => import("@/views/children/CRUDS/Admins/Show.vue"),
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "adminEdit",
              component: () => import("@/views/children/CRUDS/Admins/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "adminAdd",
              component: () => import("@/views/children/CRUDS/Admins/Form.vue"),
            },
          ],
        },
        // ******* End  Admins *******

        // ******* Start Terms *******
        {
          path: "/terms",
          component: () => import("@/views/children/Settings/Terms/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllTerms",
              component: () =>
                import("@/views/children/Settings/Terms/ShowAll.vue"),
            },

            // Edit
            {
              path: "edit/:id",
              name: "termsEdit",
              component: () =>
                import("@/views/children/Settings/Terms/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "termsAdd",
              component: () =>
                import("@/views/children/Settings/Terms/Form.vue"),
            },
          ],
        },
        // ******* End Terms *******

        // ******* Start Faq *******
        {
          path: "/faqs",
          component: () => import("@/views/children/Settings/Faq/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllFaqs",
              component: () =>
                import("@/views/children/Settings/Faq/ShowAll.vue"),
            },

            // Edit
            {
              path: "edit/:id",
              name: "faqEdit",
              component: () => import("@/views/children/Settings/Faq/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "faqAdd",
              component: () => import("@/views/children/Settings/Faq/Form.vue"),
            },
          ],
        },
        // ******* End Faq *******

        // ========== Start about ==========
        {
          path: "/about",
          component: () => import("@/views/children/Settings/AboutUs/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllAboutUs",
              component: () =>
                import("@/views/children/Settings/AboutUs/ShowAll.vue"),
            },
            // Edit
            {
              path: "edit/:id",
              name: "AboutUsEdit",
              component: () =>
                import("@/views/children/Settings/AboutUs/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "AboutUsAdd",
              component: () =>
                import("@/views/children/Settings/AboutUs/Form.vue"),
            },
          ],
        },
        // ========== End about ==========
        // ========== Start reviews ==========
        {
          path: "/how-to-work",
          component: () => import("@/views/children/CRUDS/how_to_works/Home.vue"),
          children: [
            // Show All
            {
              path: "",
              name: "All-how_to_works",
              component: () =>
                import("@/views/children/CRUDS/how_to_works/ShowAll.vue"),
            },
   
            // AddEdit
            {
              path: "form/:id?",
              name: "how_to_works_add",
              props:true,
              component: () =>
                import("@/views/children/CRUDS/how_to_works/Form.vue"),
            },
          ],
        },
        // ========== End reviews ==========
        // ========== Start reviews ==========
        {
          path: "/reviews",
          component: () => import("@/views/children/Settings/reviews/Home.vue"),
          children: [
            // Show All
            {
              path: "",
              name: "AllReviews",
              component: () =>
                import("@/views/children/Settings/reviews/ShowAll.vue"),
            },
   
            // AddEdit
            {
              path: "form/:id?",
              name: "reviewsAdd",
              component: () =>
                import("@/views/children/Settings/reviews/Form.vue"),
            },
          ],
        },
        // ========== End reviews ==========

        // ******* Start Privacy *******
        {
          path: "/privacy-policy",
          component: () => import("@/views/children/Settings/Policy/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllPrivacies",
              component: () =>
                import("@/views/children/Settings/Policy/ShowAll.vue"),
            },

            // Edit
            {
              path: "edit/:id",
              name: "privacyEdit",
              component: () =>
                import("@/views/children/Settings/Policy/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "privacyAdd",
              component: () =>
                import("@/views/children/Settings/Policy/Form.vue"),
            },
          ],
        },
        // ******* End Privacy *******

        // ========== Start Settings ==========

        {
          path: "/settings",
          component: () => import("../views/children/Settings/Home.vue"),
          children: [
            {
              path: "",
              name: "mainSettings",
              component: () => import("../views/children/Settings/General.vue"),
              redirect: () => {
                return { path: "/settings/general" };
              },
            },

            {
              path: "general",
              name: "generalSettings",
              component: () => import("../views/children/Settings/General.vue"),
            },

            {
              path: "app-settings",
              name: "appSettings",
              component: () =>
                import("../views/children/Settings/AppSettings.vue"),
            },

            {
              path: "app-amount",
              name: "appAmount",
              component: () =>
                import("../views/children/Settings/appAmount.vue"),
            },

            {
              path: "orders",
              name: "settingsOrders",
              component: () => import("../views/children/Settings/Orders.vue"),
            },

            {
              path: "wallet-limit",
              name: "wallet-limit",
              component: () =>
                import("../views/children/Settings/WalletLimit.vue"),
            },
            // {
            //   path: "social",
            //   name: "socialSettings",
            //   component: () => import("../views/children/Settings/Social.vue"),
            // },
          ],
        },
        // ========== End Settings ==========

        // ========== Start Profiles ==========
        {
          path: "/profile",

          component: () => import("@/views/children/adminProfile/Home.vue"),
          children: [
            // {
            //   path: "show",
            //   name: "showProfile",
            //   component: () => import("@/views/children/adminProfile/Show.vue"),
            // },
            {
              path: "edit",
              name: "editProfile",
              component: () => import("@/views/children/adminProfile/Form.vue"),
            },
            // {
            //   path: "password",
            //   name: "changePassword",
            //   component: () =>
            //     import("@/views/children/adminProfile/ChangePassword.vue"),
            // },
          ],
        },
        // ========== End Profiles ==========
      ],
    },

    {
      path: "/permission-required",
      name: "permission-required",
      component: () => import("../views/PermissionRequired.vue"),
    },

    //  =========== Start Redirects ===============
    {
      path: "/countries",

      redirect: () => {
        return { path: "/countries/show-all" };
      },
    },
    {
      path: "/about",

      redirect: () => {
        return { path: "/about/show-all" };
      },
    },
    // {
    //   path: "/terms",

    //   redirect: () => {
    //     return { path: "/terms/show-all" };
    //   },
    // },
    {
      path: "/privacy",

      redirect: () => {
        return { path: "/privacy/show-all" };
      },
    },
    {
      path: "/about/add",

      redirect: () => {
        return { path: "/about/show-all" };
      },
    },
    // {
    //   path: "/terms/add",

    //   redirect: () => {
    //     return { path: "/terms/show-all" };
    //   },
    // },
    {
      path: "/privacy/add",

      redirect: () => {
        return { path: "/privacy/show-all" };
      },
    },
    //  =========== End  Redirects ===============
    // Auth
    {
      path: "/login",
      name: "Login",
      component: Login,
    },

    // NotFound
    { path: "/:notFound(.*)", name: "NotFound", component: NotFound },
  ],
});

const TOKEN = store.getters["auth_module/currentUser"].token;

router.beforeEach((to, _2, next) => {
  if (to.name !== "Login" && !TOKEN) {
    next({ name: "Login" });
  } else if (to.name == "Login" && TOKEN) {
    next("/");
  } else {
    next();
  }
});

Vue.use(VueRouter);
export default router;

export default class Permission {
  setControls(path) {
    let status;
    if (localStorage.getItem("matlop_dash_User_Type") != "superadmin") {
      if (localStorage.getItem("matlop_controls")) {
        let controls = JSON.parse(localStorage.getItem("matlop_controls"));
        const found = controls?.find((control) => control.url == path);

        if (found) {
          status = false;
        } else {
          status = true;
        }
      }
    } else {
      status = false;
    }
    return status;
  }
  allowed(path) {
    let status;

    if (localStorage.getItem("matlop_dash_User_Type") != "superadmin") {
      if (localStorage.getItem("matlop_dash_permissions")) {
        let controls = JSON.parse(
          localStorage.getItem("matlop_dash_permissions")
        );

        const found = controls.find((control) => control?.url == path);

        if (found) {
          status = false;
        } else {
          status = true;
        }
      }
    } else {
      status = false;
    }

    return status;
  }
}
